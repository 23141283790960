@import "styles/AntDesign/customTheme.less";

@media (min-width: @desktop-width) {
	.send-message-container {
		width: 100%;
		height: 100%;
		border: @default-border;
		border-radius: @default-border-radius;
		margin: 16px 0 16px 16px;

		.form {
			width: 100%;
			height: 100%;
			align-items: center;

			.header-form {
				justify-content: space-between;
				padding: 20px 24px;
				width: 100%;
				font-size: 14px;
				font-weight: bold;
			}

			.content-form {
				justify-content: space-between;
				padding: 20px 24px;
				width: 100%;
				font-size: 14px;
				font-weight: bold;
				margin-top: auto;
				margin-bottom: auto;

				.dropdown {
					width: 100%;
				}
			}
		}
	}
}


@media (max-width: @desktop-width) {
	.send-message-container {
		width: 100%;
		height: 100%;
		border: @default-border;
		border-radius: @default-border-radius;
		margin-top: 1vh;

		.form {
			width: 100%;
			height: 100%;
			align-items: center;

			.header-form {
				justify-content: space-between;
				padding: 20px 24px;
				width: 100%;
				font-size: 14px;
				font-weight: bold;
			}

			.content-form {
				justify-content: space-between;
				padding: 20px 24px;
				width: 100%;
				font-size: 14px;
				font-weight: bold;
				margin-top: auto;
				margin-bottom: auto;

				.dropdown {
					width: 100%;
				}
			}
		}
	}
}

@primary-color: #2A54C5;