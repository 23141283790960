@import "styles/AntDesign/customTheme.less";

@media (min-width: @desktop-width) {
	.station-item {
		width: 100%;
		height: 8vh;
		padding: @small-margin-padding @small-margin-padding @small-margin 0;
		background-color: @default-lightgray-color;
		border-radius: @large-border-radius;
		display: flex;
		flex-direction: row;
		align-items: center;

		&:hover {
			cursor: pointer;
		}

		.item-details {
			width: 85%;
			height: 100%;
			display: flex;
			flex-direction: column;

			.item-details-first-row {
				height: 50%;
				display: flex;
				flex-direction: row;

				.item-color {
					width: 5%;
				}

				.item-title {
					width: 95%;
					padding-left: 10px;
					font-size: 14px;
					font-weight: bold;
				}
			}

			.item-details-second-row {
				height: 50%;
				padding-left: calc(5% + 10px);
				opacity: 0.4;
				font-size: 12px;
			}
		}
	}
}

@media (max-width: @desktop-width) {
	.station-item {
		width: 100%;
		height: 6vh;
		padding: @small-margin-padding @small-margin-padding @small-margin 0;
		background-color: @default-lightgray-color;
		border-radius: @large-border-radius;
		display: flex;
		flex-direction: row;
		align-items: center;

		.item-details {
			width: 85%;
			height: 100%;
			display: flex;
			flex-direction: column;

			.item-details-first-row {
				height: 50%;
				display: flex;
				flex-direction: row;

				.item-color {
					width: 2.5%;
					background-color: red;
				}

				.item-title {
					width: 95%;
					padding-left: 10px;
					font-size: 12px;
					font-weight: bold;
				}
			}

			.item-details-second-row {
				height: 50%;
				padding-left: calc(5% + 10px);
				opacity: 0.4;
				font-size: 12px;
			}
		}
	}
}

@primary-color: #2A54C5;