@import "styles/AntDesign/customTheme.less";

.antd-custom-card {
	border: @default-border;
	border-radius: @default-border-radius;
	width: 15%;
	max-height: 78px;
	display: flex;
	align-items: center;

	.ant-card-body {
		color: @default-blue-color;
		font-weight: 600;
		width: 100%;
	}

	.ant-card-meta-detail {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		width: 100%;

		.ant-card-meta-title {
			font-style: normal;
			font-weight: 600;
			letter-spacing: 0.06em;
			text-transform: uppercase;
			color: @default-blue-color;
			font-size: calc(0.4vw + 0.8vh);
			// font-size: 14px;
			word-wrap: break-word;
			margin-bottom: 0;
			display: inline-block;
			white-space: normal;
		}

		.ant-card-meta-description {
			font-style: normal;
			font-weight: 300;
			font-size: calc(0.5vw + 0.8vh);
			// font-size: 16px;
			color: black;
			width: 100%;
			margin: 0;
		}
	}
}

@media (max-width: @desktop-width) {
	.antd-custom-card {
		width: 49%;

		.ant-card-meta-detail {
			.ant-card-meta-title {
				font-size: 14px;
			}

			.ant-card-meta-description {
				font-size: 16px;
			}
		}
	}
}

@primary-color: #2A54C5;