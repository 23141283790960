@import "styles/AntDesign/customTheme.less";

@media (min-width: @desktop-width) {
	.details-container {
		padding: 0 8px;
		border: @default-border;
		border-radius: @default-border-radius;
		height: 100%;
		width: 49%;

		.details-header {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			& .ant-tabs-tab {
				font-size: 12px;
			}

			& .ant-picker-input > input {
				font-size: 12px;
			}
		}

		.details-chart {
			height: 92%;
			width: 100%;
			padding-top: 10px;
		}
	}
}

@media (max-width: @desktop-width) {
	.details-container {
		padding: 0 8px;
		border: @default-border;
		border-radius: @default-border-radius;
		width: 100%;
		height: 50vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.ant-picker-panels {
		flex-direction: column;
		width: 80%;
	}

	& .ant-tabs-tab {
		font-size: 10px;
	}

	& .ant-picker-input > input {
		font-size: 10px;
	}

	.details-chart {
		height: 96%;
		width: 100%;
		padding-top: 20px;
	}
}

@primary-color: #2A54C5;