@import "styles/AntDesign/customTheme.less";

#background {
	position: absolute;
	right: 0%;
	top: 10%;
}

.success-container {

	align-self: flex-start;

	.success-details {
		position: relative;
		display: flex;
		flex-direction: column;
		margin-top: 20vh;
		width: 45vw;
		margin-left: 5vw;
		gap: 7vh;

		.title {
			font-style: normal;
			font-weight: 600;
			font-size: 48px;
			line-height: 56px;
			color: #000000;
			z-index: 2;
		}

		button {
			width: fit-content;
			border-radius: 10px;
		}
	}
}

@media (max-width: @desktop-width) {
	.success-details {
		.title {
			font-style: normal;
			font-weight: 600;
			font-size: 32px;
			line-height: 40px;
			color: #000000;
			z-index: 2;
		}
	}
}

@primary-color: #2A54C5;