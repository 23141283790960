@import "styles/AntDesign/customTheme.less";

.customModal {

	.ant-modal-confirm-body {
		.anticon-exclamation-circle {
			display: none;
		}

		.custom-content {
			height: 25vh;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;

			.content-title {
				text-align: center;
				font-size: @font-size-large;
				font-weight: bold;
			}

			.content-description {
				text-align: center;
				align-items: center;
				font-size: @font-size-default;
			}
		}
	}

	.ant-modal-confirm-btns {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		width: 100%;
		gap: 2vh;

		button {
			width: 90%;
			border-radius: @large-border-radius;
		}

		.ant-btn-primary {
			margin: 0 !important;
		}
	}
}

@media (min-width: @desktop-width) {
	.charger-button {
		margin-right: 2px;
		margin-left: 2px;
		border-radius: 10px;
		height: 40px;
		font-weight: 600;
	}

	.charger {
		width: 100%;

		.charger-container-border {
			border: @default-border;
			border-radius: @default-border-radius;
			margin-top: 16px;
		}

		.chargerTransactions {
			border: 1px solid black;
		}

		.charger-transactions-table {
			margin-top: 16px;
		}

	}

	.charger-container-border {
		border: @default-border;
		border-radius: @default-border-radius;
		margin-top: 16px;
	}

	.chargerTransactions {
		border: 1px solid black;
	}

	.charger-transactions-table {
		margin-top: 16px;
	}

	.table-header {
		color: @default-blue-color;
		font-weight: 600;
	}
}
@media (max-width: @desktop-width) {
	.charger-button {
		margin-right: 2px;
		margin-left: 2px;
		border-radius: 10px;
		//height: 40px;
		font-weight: 600;
	}

	.charger {
		width: 100%;

		.charger-container-border {
			border: @default-border;
			border-radius: @default-border-radius;
			margin-top: 16px;
		}

		.charger-transactions-table {
			margin-top: 16px;
		}
	}
}

@primary-color: #2A54C5;