@import "styles/AntDesign/customTheme.less";
@import "styles/mixins.less";

.header {
	display: flex;
	justify-content: flex-start;
	margin: 0;
	width: 100%;
	flex-wrap: wrap;

	img {
		height: 32px;
		margin: 8px 16px;

		&:hover {
			cursor: pointer;
		}
	}

	.buttons-container {
		display: flex;
		flex: 1;
		justify-self: flex-end;

		.emailButton {
			span {
				margin: 0;
			}
		}
	}

	.desktopHeader {
		width: auto;

		.ant-menu-title-content {
			font-weight: 600;
		}

		.ant-menu-item {
			height: 100%;
		}

		.ant-menu-item::after {
			content: none;
		}

		.ant-menu-item>span {
			display: flex;
			height: 100%;
			border-top: 2px solid white;
			transition: 0.3s;

			&:hover {
				color: #48a4ff;
				border-top: 2px solid #48a4ff;
			}
		}

		.ant-menu-item-selected>span {
			border-top: 2px solid @default-blue-color;
		}
	}

	.ant-menu-horizontal {
		border-bottom: 0;
	}

	.subHeader-container {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		flex: 1;

		.subHeader {
			margin: 0 @default-margin-padding;
		}

		.subHeader:first-of-type {
			margin-left: auto;
		}

		.anticon {
			height: 100%;
			display: flex;
			align-items: center;

			svg {
				color: @default-blue-color;
				height: 30% !important;
				width: auto;
				display: inline-block;
			}
		}
	}
}

.ant-menu-submenu-selected::after {
	content: none !important;
}

@media (max-width: @mobile-width) {
	.header {
		height: @header-height;
	}
}

@primary-color: #2A54C5;