@import "styles/AntDesign/customTheme.less";

@media (min-width: @desktop-width) {
	.support-container {
		display: flex;

		// .support-cards-container {
		// 	height: 100%;
		// 	width: 60%;
		// 	display: flex;
		// 	flex-direction: column;
		// 	justify-content: space-between;
		// 	align-items: center;
		// }

		.send-message-form {
			padding-right: 30px;
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
		}
	}
};

@media (max-width: @desktop-width) {
	.support-container {
		display: flex;

		// .support-cards-container {
		// 	height: 100%;
		// 	width: 100%;
		// 	display: flex;
		// 	flex-direction: column;
		// 	justify-content: space-between;
		// 	align-items: center;
		// }

		.send-message-form {
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
		}
	}
}

@primary-color: #2A54C5;