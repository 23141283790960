@import "styles/AntDesign/customTheme.less";

@media (min-width: @desktop-width) {
	.charger-info-header {
		display: flex;

		.charger-Id {
			font-weight: bold;
			margin: 16px;
		}

		.charger-status {
			padding-top: 16px;
			width: 100px;
		}
	}

	.tag {
		width: 90px;
		text-align: center;
		margin: auto;
	}

	.get-charger-info-content {
		display: flex;

		.info-card {
			min-width: 140px;
			margin: 16px 20px 16px 16px;

			.key {
				color: @default-blue-color;
				font-weight: 600;
			}

			.property {
				font-weight: 600;
			}
		}
	}
}

@media (max-width: @desktop-width) {
	.charger-info-header {
		display: flex;

		.charger-Id {
			font-weight: bold;
			margin: 10px;
		}

		.charger-status {
			padding-top: 10px;
		}
	}
	.tag {
		//width: 90px;
		text-align: center;
		margin: auto;
	}

	.get-charger-info-content {
		display: flex;

		.info-card {
			//min-width: 140px;
			//margin: 16px 20px 16px 16px;

			.key {
				color: @default-blue-color;
				font-weight: 600;
			}

			.property {
				font-weight: 600;
			}
		}
	}
}

@primary-color: #2A54C5;