@import "styles/AntDesign/customTheme.less";

.popover-container {
	width: 100%;
	padding-top: 0 !important;
	top: @header-height !important;
	left: 0 !important;

	.ant-popover-inner {
		margin: 0;

		.ant-popover-inner-content {
			padding: 0;
			background-color: rgb(245, 245, 245);

			.ant-list-item {
				padding: @default-margin-padding;
				border-top: 0.5px solid rgba(200, 200, 200, 0.549);
				text-align: right;
				width: 100%;
				display: flex;
				justify-content: flex-end;
				font-weight: 600;
				font-size: medium;
				border-right: 3px solid rgb(245, 245, 245);
				transition: 0.3s;

				.anticon {
					margin-left: @default-margin-padding;
				}
			}

			.active-item {
				border-right: 3px solid @default-blue-color;
				color: @default-blue-color;
				background-color: rgba(211, 235, 248, 0.504);
			}
		}
	}
}

@primary-color: #2A54C5;