@import "styles/AntDesign/customTheme.less";

@media (min-width:@desktop-width) {
    .export-available-stations {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .export-section-container {
            width: 85%;
            height: 90%;
            margin-top: 10%;
            border-right: 1.5px solid rgba(0, 0, 0, 0.1);

            .export-station-search {
                width: 80%;
                padding: 0 20px 0 20px;
                background-size: 10px;
                background: url("../../../../assets/search.svg") no-repeat left;
                margin-top: 10px;
                border-radius: @large-border-radius;
            }
            .available-stations-list {
                display: flex;
                flex-direction: column;
                margin-top: 2vh;
                height: 80%;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    background-color: #fff;
                    width: 14px;
                }

                &::-webkit-scrollbar-track {
                    background-color: #fff;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #babac0;
                    border-radius: 16px;
                    border: 4px solid #fff;
                }

                &::-webkit-scrollbar-button {
                    display: none;
                }

                .available-station-container {
                    height: 5vh;
                    margin-bottom: 1vh;
                    padding: 10px;

                    label {
                        font-size: 12px;
                        font-weight: bold;
                        cursor:pointer;
                    }

                    input {
                        -webkit-appearance: none;
                        &:checked {
                            &:after {
                                margin-left: 20px;
                                content: "\2713";
                                color: @default-blue-color;
                            }
                        }
                    }
                }

                .selected-station-container {
                    width: fit-content;
                    padding:10px;
                    margin-bottom: 5%;
                    border-radius: @default-border-radius;
                    background-color: @default-gray-color;

                    label {
                        font-size: 12px;
                        font-weight: bold;
                        cursor:pointer;
                    }

                    input {
                        -webkit-appearance: none;
                        &:checked {
                            &:after {
                                margin-left: 20px;
                                content: "\2713";
                                color: @default-blue-color;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: @mobile-width) {
    .export-available-stations {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .export-section-container {
            width: 85%;
            height: 90%;
            margin-top: 10%;

            .export-station-search {
                width: 80%;
                padding: 0 20px 0 20px;
                background-size: 10px;
                background: url("../../../../assets/search.svg") no-repeat left;
                margin-top: 10px;
                border-radius: @large-border-radius;
            }

            .available-stations-list {
                display: flex;
                flex-direction: column;
                margin-top: 2vh;
                height: 80%;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    background-color: #fff;
                    width: 14px;
                }

                &::-webkit-scrollbar-track {
                    background-color: #fff;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #babac0;
                    border-radius: 16px;
                    border: 4px solid #fff;
                }

                &::-webkit-scrollbar-button {
                    display: none;
                }

                .available-station-container {
                    height: 5vh;
                    margin-bottom: 1vh;
                    padding: 10px;

                    label {
                        font-size: 12px;
                        font-weight: bold;
                        cursor:pointer;
                    }

                    input {
                        -webkit-appearance: none;
                        &:checked {
                            &:after {
                                margin-left: 20px;
                                content: "\2713";
                                color: @default-blue-color;
                            }
                        }
                    }
                }

                .selected-station-container {
                    width: fit-content;
                    padding:10px;
                    margin-bottom: 5%;
                    border-radius: @default-border-radius;
                    background-color: @default-gray-color;

                    label {
                        font-size: 12px;
                        font-weight: bold;
                        cursor:pointer;
                    }

                    input {
                        -webkit-appearance: none;
                        &:checked {
                            &:after {
                                margin-left: 20px;
                                content: "\2713";
                                color: @default-blue-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

@primary-color: #2A54C5;