@import "styles/AntDesign/customTheme.less";

.main-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	.section-graphs {
		height: 59%;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.section-cards {
		height: 39%;
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		gap: 2%;

		.summary-card-container {
			position: relative;
			width: 15%;
			max-height: 78px;

			.ant-card-body {
				width: 100%;
			}

			.action-image {
				transition: .5s ease;
				border-radius: @default-border-radius;
				opacity: 0;
				position: absolute;
				z-index: 2;
				background-color: @default-blue-color;
				top: 50%;
				left: 50%;
				width: 100%;
				height: 100%;
				transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);

				& svg {
					position: relative;
					width: 25%;
					height: 50%;
					top:25%;
					left:37.5%;
				}
			}
			.ant-card:hover {
				opacity: 0.2;

			}
			.action-image:hover {
				opacity: 0.95;
				cursor: pointer;
				img {
					opacity: 1;
				}
			}
		}
	}
}


@media(max-width:@desktop-width) {
	.main-container {
		height: fit-content;
		display: flex;
		flex-direction: column;
		align-items: center;

		.section-graphs {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content:space-between;
			gap:@small-margin;
		}

		.section-cards {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			&>div {
				margin-top: @small-margin;
			}

			.customise-stats-section {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.customise-active-stats-section, .customise-inactive-stats-section {
					width: 49%;
					display: flex;
					flex-direction: column;
					gap: 2vh;
				}
			}
		}

	}
}

@primary-color: #2A54C5;