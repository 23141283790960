@import "styles/AntDesign/customTheme.less";

.profile-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 0;
	border-radius: 7px;
	border: 1px solid @default-gray-color;

	.ant-tabs {
		padding: 0 @default-margin-padding;
	}

	.ant-tabs-nav {
		margin: 0;
	}

	.ant-tabs-nav::before {
		border-width: 0;
	}

	.profile-main-section {
		width: 100%;
		height: 90%;
	}
}

.profile-buttons-section {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

@media (max-width: @desktop-width) {
	.profile-container {
		width: 100%;
	}
}

@primary-color: #2A54C5;