@import "styles/AntDesign/customTheme.less";

.back-button {
	grid-area: backButtonColumn;
}

.container-content {
	width: 100%;

	.container-title-section {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.container-title {
			padding-bottom: @small-margin-padding;
			margin-bottom: 0;
			height: fit-content;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			.editable {
				margin:0;

				h1 {
					margin: 0;
				}
			}
			.container-edit-button {
				width: fit-content;
				border-radius: 5px;
				background-color: rgba(0, 0, 0, 0.04);
				margin-left: @default-margin-padding;
				font-weight: 600;
			}
		}

		.container-actionsContainer {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.container-subTitle {
		padding-bottom: @default-margin-padding;
		color: @default-blue-color;
	}

	.container-main-section {
		height: 85%;
		width: 100%;
		display: flex;
		flex-direction: row;

		.container-main-section-left {
			width: 80%;
			height: 100%;
		}

		.container-main-section-right {
			width: 80%;
			height: 100%;
		}
	}

	.container-main-section-no-flex {
		height: 85%;
		width: 100%;

		.container-main-section-left {
			width: 80%;
			height: 100%;
		}

		.container-main-section-right {
			width: 80%;
			height: 100%;
		}
	}
}

.container {
	display: grid;
	width: 94%;
	grid-auto-columns: fit-content(20%) 0.96fr;
	grid-template-areas:
		"backButtonColumn containerContent";
}

.container-without-back-butt {
	width: 94%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

@media (max-width: @desktop-width) {
	.container {
		display: unset;
	}

	.container-content {
		display: flex;
		flex-direction: column;
		width: 100%;

		.container-title {
			font-size: 18px;
			font-weight: bold;
		}
	}

	.container-main-section {
		width: 100%;
		margin-bottom: @large-margin-padding;
	}
}

@media (min-width: 764px) {
	.container,
	.container-without-back-butt {
		height: 85%;
	}

	.container-content {
		height: 100%;
		.container-title {
			font-size: 30px;
			font-weight: bold;
		}
		.container-header {
			height: fit-content;
		}
	}

	.container-without {
		height: 85%;
	}
}

@primary-color: #2A54C5;