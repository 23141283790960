@import "styles/AntDesign/customTheme.less";

.tab-notifications {
	padding: @default-margin-padding;
	display: flex;
	height: 100%;

	.message {

		font-size: 50px;
		font-weight: 600;
		opacity: .6;
		margin: auto;
	}

}

@primary-color: #2A54C5;