@import "styles/AntDesign/customTheme.less";


@media (min-width: @desktop-width) {
    .export-success-container {
        height: 100%;
        display: flex;
        flex-direction: column;

        .logo-container {
            position: inherit;
        }

        .export-success-details {
            display: flex;
            flex-direction: row;
            height: 100%;

            .export-success-details-left {
                width: 50%;
                padding-top: 10%;

                .title {
                    font-size: @font-size-xxlarge;
                    font-weight: 600;
                    margin-top: @small-margin;
                }

                .subtitle {
                    font-size: @font-size-xlarge;
                    font-weight: 400;
                    margin-top:@small-margin;
                }

                button {
                    margin-top: 20%;
                }
            }
        }
    }
}

@media (max-width:@mobile-width) {

    .export-success-container::before {
        content: "";
        background-image: url('../../../../assets/export-success.svg');
        position: absolute;
        height: 80vh;
        width: 100vw;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.35;
    }

    .export-success-container {
        height: 90%;

        .export-success-details {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 40%;
            margin: auto;

            svg {
                width: 40%;
            }
            .title {
                font-size: @font-size-xxlarge;
                font-weight: 600;
                margin-top: @small-margin;
                opacity: 1;
            }

            .subtitle {
                font-size: @font-size-xlarge;
                font-weight: 400;
                margin-top:@small-margin;

            }

            button {
                margin-top: 20%;
                width: 50%;
            }
        }
    }
}

@primary-color: #2A54C5;