/* stylelint-disable CssSyntaxError */
@import 'styles/AntDesign/customTheme.less';
@import 'styles/mixins.less';

.tab-profile, .tab-profile-main-section, .tab-profile-buttons-section {
	width: 100%;
}

.tab-profile {
	display: flex;
	flex-direction: column;
	height:100%;
	align-items: center;

	.ant-form {
		width:100%;
		height: 100%;
	}

	.tab-profile-main-section {
		height: 85%;
		display: flex;
		padding: @small-margin-padding @default-margin-padding;
		padding-bottom: @large-margin-padding;

		.photo-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: #F8F9FA;
			margin-right: @large-margin-padding;
			width: @profile-photo-container;

			.profileImage {
				.center();
				width: @profile-photo-width-height;
				height: @profile-photo-width-height;
				margin-top: 20%;
				border-radius: @profile-photo-border-radius;
				background-color: @default-blue-color;

				img {
					width: 100%;
					height: 100%;
					overflow: hidden;
					object-fit: cover;
					border-radius: @profile-photo-border-radius;
				}

				.anticon svg {
					color: white;
					height: 2.5em;
					width: 3em;
				}
			}

			#file-input {
				visibility: hidden;
				position: absolute;
			}

			.label-button {
				background-color: rgba(0, 0, 0, 0.04);
				border: 1px solid rgba(0, 0, 0, 0.1);
				border-radius: 5px;
				padding: 4px @small-margin-padding;
				margin: 10px 0;
				font-weight: 600;
			}

			.label-button:hover {
				cursor: pointer;
			}

			.description-photo {
				color: rgba(0, 0, 0, 0.4);
				text-align: center;
			}
		}

		.inputs-container {
			display: flex;
			flex-direction: column;
			justify-content: stretch;
			width:50%;
			height: 100%;

			.inputs-row, .ant-form {
				width:100%;
			}

			.ant-row{
				margin: 0;
			}
			.inputs-row {
				display: flex;
				justify-content: space-between;

				.ant-row {
					display: inline-flex;
					width: 48%;
				}
			}

			.inputs-row:last-of-type {
				.ant-row {
					margin-bottom: 0;
				}
			}

			.ant-row {
				margin-right: @small-margin-padding;

				.ant-form-item-label {
					font-weight: 400;
				}

				.ant-input {
					padding: @small-margin-padding;
					border-radius: 5px;
				}
			}
		}

	}

	.tab-profile-buttons-section {
		height: 15%;
		display: flex;
		justify-content: flex-end;
		border-top: 1px solid @default-gray-color;
		align-items: center;
		height: auto;
	}
}

@media (max-width: @desktop-width) {

	.tab-profile {
		.tab-profile-main-section {
			display: flex;
			flex-direction: column;

				.photo-container {
					width: 100%;
					margin: 0;
					justify-content: center;
					align-items: center;
					padding: @large-margin-padding 0;

					.profileImage {
						margin: 0;
					}
				}

				.inputs-container {
					width: 100%;
					margin-top: @default-margin-padding;

					.inputs-row {
						flex-direction: column;

						.ant-row {
							width: 100%;
						}
					}

					.ant-row {
						margin-right: 0;
						margin-bottom: @default-margin-padding !important;
					}
				}
		}
	}
}

@primary-color: #2A54C5;