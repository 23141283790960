@import 'styles/AntDesign/customTheme.less';
@import 'styles/mixins.less';

.verify-email-container {
	display: flex;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;

	img {
		width: 50%;
		height: auto;
	}

	.verify-email-details-container {
		width: 50%;
	}

	button {
		border-radius: @large-border-radius;
		width: 100%;
		margin-top: 1vh;
	}
}

@media (min-width: @desktop-width) {
	.verify-email-container {

		.verify-email-details {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			padding: @default-margin-padding 25%;

			.title {
				margin-top: 3vh;
				font-style: normal;
				font-weight: 600;
				font-size: @font-size-xlarge;
				color: #000000;
				margin-top: auto;
			}

			.subtitle {
				font-style: normal;
				font-weight: 400;
				font-size: @font-size-default;
				color: #000000;
				opacity: 0.4;
				margin-bottom: 1vh;
			}

			#verify-email {
				margin-bottom: auto;
			}

			.credits {
				text-align: center;
			}
		}
	}
}

@media (max-width: @desktop-width) {
	.verify-email-container {
		flex-direction: column;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.verify-email-details-container {
			.center();
			z-index: 10;
			height: 100vh;
			width: 80%;
			margin: auto;

			.verify-email-details {
				display: flex;
				flex-direction: column;
				border-radius: 10px;
				background-color: rgb(255, 255, 255);
				height: auto;
				width: 100%;
				padding: @default-margin-padding;

				.title,
				.subtitle {
					margin-bottom: @default-margin-padding;
				}

				.title {
					font-weight: bold;
				}

				.credits {
					margin-top: @default-margin-padding;
				}
			}
		}
	}
}

@primary-color: #2A54C5;