@import "styles/AntDesign/customTheme.less";

.ant-breadcrumb {
	height: 100%;
	display: flex;
	align-items: center;

	& > span {
		display: flex;
		align-items: center;

		.item:hover {
			cursor: pointer;
		}

		.last-item {
			color: @default-blue-color;
		}

		.item,
		.last-item {
			font-weight: 600;
		}

		.ant-breadcrumb-separator {
			width: 5px;
			height: 100%;
			display: inline-flex;
			justify-content: center;
			align-items: center;

			.separator {
				display: inline-block;
				width: 100%;
				height: 5px;
				background-color: rgba(0, 0, 0, 0.45);
				border-radius: 50%;
			}
		}
	}
}

@primary-color: #2A54C5;