@import "styles/AntDesign/customTheme.less";

@media (min-width:@desktop-width) {
    .export-modal-type {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .export-section-container {
            width: 85%;
            height: 90%;
            margin-top: 10%;
            border-right: 1.5px solid rgba(0, 0, 0, 0.1);
        }
    }

    .radio-container {
        padding:10px;
        border-radius: @default-border-radius;
        width: fit-content;
        margin-bottom: 5%;

        label {
            font-size: 12px;
            font-weight: bold;
            cursor:pointer;
        }

        input {
            -webkit-appearance: none;
            &:checked {
                &:after {
                    margin-left: 20px;
                    content: "\2713";
                    color: @default-blue-color;
                }
            }
        }
    }

    .radio-container-selected {
        width: fit-content;
        padding:10px;
        margin-bottom: 5%;
        border-radius: @default-border-radius;
        background-color: @default-gray-color;

        label {
            font-size: 12px;
            font-weight: bold;
            cursor:pointer;
            color: @default-blue-color;
        }

        input {
            -webkit-appearance: none;
            &:checked {
                &:after {
                    margin-left: 20px;
                    content: "\2713";
                    color: @default-blue-color;
                }
            }
        }
    }
}

@media (max-width:@mobile-width) {
    .export-modal-type {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .export-section-container {
            width: 85%;
            height: 90%;
            margin-top: 10%;

        }
    }

    .radio-container {
        padding:10px;
        border-radius: @default-border-radius;
        width: fit-content;
        margin-bottom: 5%;

        label {
            font-size: 12px;
            font-weight: bold;
            cursor:pointer;
        }

        input {
            -webkit-appearance: none;
            &:checked {
                &:after {
                    margin-left: 20px;
                    content: "\2713";
                    color: @default-blue-color;
                }
            }
        }
    }

    .radio-container-selected {
        width: fit-content;
        padding:10px;
        margin-bottom: 5%;
        border-radius: @default-border-radius;
        background-color: @default-gray-color;

        label {
            font-size: 12px;
            font-weight: bold;
            cursor:pointer;
            color: @default-blue-color;
        }

        input {
            -webkit-appearance: none;
            &:checked {
                &:after {
                    margin-left: 20px;
                    content: "\2713";
                    color: @default-blue-color;
                }
            }
        }
    }
}

@primary-color: #2A54C5;