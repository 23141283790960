@import "styles/AntDesign/customTheme.less";

.reset-password-form {
	display: flex;
	flex-direction: column;

	.sign-up-trouble {
		margin-top: 1vh;
		font-weight: 600;
		font-size: 14px;
		color: #000000;
	}

	.sign-up-trouble:hover {
		cursor: pointer;
		color: #2a54c5;
	}

	#reset-password {
		margin-top: 2vh;
		width: 100%;
		border-radius: 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}

	#sign-in {
		margin-top: 1.5vh;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}

	.pass-rules {
		font-size: @font-size-small;
		color: @gray-hint-color;
		font-weight: 600;
	}

}

@primary-color: #2A54C5;