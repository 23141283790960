@import "styles/AntDesign/customTheme.less";

@media (min-width:@desktop-width) {
    .export-modal-period {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .export-section-container {
            width: 85%;
            height: 90%;
            margin-top: 10%;
            border-right: 1.5px solid rgba(0, 0, 0, 0.1);

            .export-range-picker {
                padding-top:10px;
                width: 90% !important;
            }
        }
    }
}

@media (max-width:@mobile-width) {
    .export-modal-period {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .export-section-container {
            width: 85%;
            height: 90%;
            margin-top: 10%;

            .export-range-picker {
                padding-top:10px;
                width: 90% !important;
            }

        }
    }
}

@primary-color: #2A54C5;