@import "styles/AntDesign/customTheme.less";

@media (min-width: @desktop-width) {
	.stations-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		width: 100%;
	}
}

@media (max-width: @desktop-width) {
	.stations-container {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
}

@primary-color: #2A54C5;