@import "styles/AntDesign/customTheme.less";


@media (min-width:@desktop-width) {
	.export-modal-desktop {
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: 1;
		padding-top: @xlarge-margin-padding;
		left: 0;
		top: 0;
		overflow: auto;
		background-color: rgba(0, 0, 0, 0.65);

		.export-modal-close {
			position: fixed;
			z-index: 1;
			top: 20px;
			left: 87.5%;
			width: 12.5%;
			height: 30px;
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;

			span {
				color: white;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 21px;
				text-align: right;
			}

			:last-child:hover {
				cursor: pointer;
			}
		}

		.export-modal-container {
			height: 100%;
			width: 100%;
			background-color: @default-white-color;
			border-radius: @xlarge-border-radius @xlarge-border-radius 0px 0px;
			padding: @large-margin-padding @xlarge-margin-padding @large-margin-padding @xlarge-margin-padding;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.export-modal-title-section {
				height: 15%;
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				.export-title {
					font-style: normal;
					font-weight: 400;
					padding-bottom: 10px;
					font-size: @font-size-xlarge;
					line-height: 38px;
				}

				.export-subtitle {
					color: #555555;
					font-style: normal;
					font-weight: 400;
					font-size: @font-size-default;
					line-height: 16px;
					display: flex;
					align-items: center;
				}
			}

			.export-modal-content {
				height: 72.5%;
				width: 100%;
				padding-top: 20px;
				display: flex;
				flex-grow: 1;
				flex-basis: 0;
				flex-direction: row;
				justify-content: space-between;
			}

			.export-modal-actions {
				height: 9%;
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}
	}
}

@media (max-width:@mobile-width) {
	.export-modal-close {
		position: fixed;
		z-index: 3;
		right: 0;
		top: 3.5%;
		cursor: pointer;
		padding-right: @xlarge-margin-padding;
		font-size: @font-size-large;
		font-weight: 600;
	}

	.export-modal-mobile {
		position: fixed;
		padding-top: 100px;
		width: 100%;
		height: 100%;
		z-index: 2;
		display: flex;
		flex-direction: column;
		background-color: @default-white-color;

		.export-modal-container {
			width: 100%;
			height: 60%;
			display: flex;
			flex-direction: row;

			.export-modal-content {
				width: 70%;
				height: 100%;
				padding-left: 50px;
				border-right: 1.5px solid rgba(0, 0, 0, 0.1);
			}

			.export-modal-stepper {
				width: 28%;
				height: 100%;
				padding-left: 20px;
			}

		}

		.export-modal-actions {
			margin-top: 20%;
			height: 10%;
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
		}
	}
}

@primary-color: #2A54C5;