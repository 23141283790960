@import 'styles/AntDesign/customTheme.less';
@import 'styles/mixins.less';

.loadingScreen {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	background-color: @lighter-blue-color;
	position: absolute;
	top: 0;
	left: 0;
	.center();

	.loadingScreenSubcontainer {
	.center();
		flex-direction: column;

		img {
			margin: @large-margin-padding;
			margin-top: 0;
			width: 50%;
		}

		p {
			color: white;
			font-size: @font-size-default;
		}
	}
}

@primary-color: #2A54C5;