.login-form {
	display: flex;
	flex-direction: column;

	.login-trouble {
		margin-top: 1vh;
		font-weight: 600;
		font-size: 14px;
		color: #000000;
	}

	.login-trouble:hover {
		cursor: pointer;
		color: #2a54c5;
	}

	button {
		width: 100%;
		border-radius: 10px;
	}

	#login {
		margin-top: 5vh;
	}

	#sign-up {
		margin-top: 1.5vh;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}
}

@primary-color: #2A54C5;