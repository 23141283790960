@import "styles/AntDesign/customTheme.less";

@media (min-width: @desktop-width) {
	.station-list-container {
		width: 25%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		border: @default-border;
		border-radius: @default-border-radius;

		.available-stations {
			height: 85%;
			width: 100%;
			padding: @default-margin-padding;
			display: flex;
			flex-direction: column;

			.title {
				height: 10%;
				font-size: 16px;
				font-weight: bold;
			}

			.stations-list {
				height: 90%;
				overflow: auto;
				display: flex;
				flex-direction: column;
				gap: 1vh;

				&::-webkit-scrollbar {
					background-color: #fff;
					width: 16px;
				}

				&::-webkit-scrollbar-track {
					background-color: #fff;
				}

				&::-webkit-scrollbar-thumb {
					background-color: #babac0;
					border-radius: 16px;
					border: 4px solid #fff;
				}

				&::-webkit-scrollbar-button {
					display: none;
				}
			}
		}

		.stations-actions {
			height: 15%;
			width: 100%;
			padding: @small-margin-padding;
			border-top: @default-border;
			display: flex;
			justify-content: space-evenly;
			align-items: center;

			& > button {
				width: 40%;
				font-size: 12px;
			}

			.compare-inactive {
				opacity: 0.6;
				color: @default-blue-color;
			}

			.compare-active {
				color: white;
				background-color: @default-blue-color;
			}
		}
	}
}

@media (max-width: @desktop-width) {
	.stations-list {
		.station-item {
			margin-bottom: 10px;
		}
	}

	.stations-actions {
		height: 10vh;
		width: 100%;
		padding: @small-margin-padding;
		border-top: @default-border;
		display: flex;
		justify-content: space-evenly;
		align-items: center;

		& > button {
			width: 40%;
			font-size: 12px;
		}

		.compare-inactive {
			opacity: 0.6;
			color: @default-blue-color;
		}

		.compare-active {
			color: white;
			background-color: @default-blue-color;
		}
	}
}

@primary-color: #2A54C5;