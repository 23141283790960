@import "styles/AntDesign/customTheme.less";

.expanded-main-section {
    display: flex;
    flex-direction: column;
    border-left: @default-border;
    width: 100%;
    height: 100%;
    padding-left: 10%;

    .expanded-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;     
    }
    
    .expanded-inactive-stats {
        display: flex;
        flex-direction: column;
        gap: 1vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
            background-color: #fff;
            width: 16px;
        }
        
        &::-webkit-scrollbar-track {
            background-color: #fff;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: #babac0;
            border-radius: 16px;
            border: 4px solid #fff;
        }
        
        &::-webkit-scrollbar-button {
            display: none;
        }
        
        .summary-card-container {
            position: relative;
            
            .ant-card-body {
                width: 100%;
            }
            
            .action-image {
                transition: .5s ease;
                border-radius: @default-border-radius;
                opacity: 0;
                position: absolute;
                z-index: 2;
                background-color: @default-blue-color;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                
                & svg {
                    position: relative;
                    width: 25%;
                    height: 50%;
                    top:25%;
                    left:37.5%;
                }
            }
            .ant-card:hover {
                opacity: 0.2; 
            }
            .action-image:hover {
                opacity: 0.95;
                cursor: pointer;
                img {
                    opacity: 1;
                }
            }
        }
    }
}
@primary-color: #2A54C5;