@import "styles/AntDesign/customTheme.less";

@media(min-width:@desktop-width) {
	.station-map-container {
		width: 73.5%;
		height: 100%;
		border:@default-border;
		border-radius: @default-border-radius;

		.station-map {
			padding:10px;
			height: 100%;
			width: 100%;
			overflow: hidden;
		}
	}
}

@primary-color: #2A54C5;