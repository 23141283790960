@import "~antd/dist/antd.less";
@import "styles/AntDesign/customTheme.less";

* {
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.ant-picker-range {
	.ant-picker-clear {
		display: none !important;
	}
}

.ant-spin-container {
	ul.ant-table-pagination {
		li:nth-last-child(2) {
			display: none !important;
		}
		li.ant-pagination-item-active {
			display: inline-block !important;
		}
	}
}

@media (max-width: 768px) {
	.app-container {
		margin: 0;
		padding: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
}

@media (min-width: @desktop-width) {
	#root {
		height: 100%;
		width: 100%;
	}

	.app-container {
		margin: 0;
		padding: 0;
		height: 100%;
		max-height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
}

@primary-color: #2A54C5;