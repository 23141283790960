.reset-pass-form {
	display: flex;
	flex-direction: column;

	.reset-pass-cust-service {
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: -0.1px;
		color: #000000;
	}

	#contact-cs:hover {
		cursor: pointer;
	}

	button {
		width: 100%;
		border-radius: 10px;
	}

	#submit {
		margin-top: 5vh;
	}

	#sign-in {
		margin-top: 1.5vh;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}
}

@primary-color: #2A54C5;