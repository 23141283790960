@import "styles/AntDesign/customTheme.less";

.pie-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 49%;
	border: @default-border;
	border-radius: @default-border-radius;
	padding: 8px;

	.title {
		font-weight: bold;
		font-style: normal;
		font-size: 16px;
		height: 15%;
	}

	.pie-details {
		height: 85%;
		display: flex;
		justify-content: space-between;

		.pie-legend {
			width: 48%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 1vh;

			.legend-item {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				background-color: @default-lightgray-color;

				.item-color {
					width: 10%;
				}

				.item-title {
					width: 35%;
					font-style: normal;
					font-weight: 700;
				}

				.item-value {
					width: 45%;
					text-align: center;
				}
			}
		}
	}
}

@media (max-width: @desktop-width) {
	.pie-container {
		width: 100%;
		height: 50vh;

		.title {
			font-size: 24px;
		}

		.pie-details {
			grid-template-columns: 35% 65%;

			.item-title {
				font-size: 12px;
			}

			.item-value {
				font-size: 12px;
			}
		}
	}
}

@media (min-width: 1800px) {
	.pie-container {
		.title {
			font-size: 20px;
		}

		.pie-details {
			.item-title {
				font-size: 18px;
			}

			.item-value {
				font-size: 18px;
			}
		}
	}
}

@primary-color: #2A54C5;