@import "styles/AntDesign/customTheme.less";

.dark-button {
	background-color: @default-blue-color;
	color: white;
	border-radius: 10px;
	height: 40px;
	font-weight: 600;
	min-width: 40px;
	margin: 10px 5px;
}

@primary-color: #2A54C5;