@import "styles/AntDesign/customTheme.less";

@media (min-width:@desktop-width) {

        .export-modal-format {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;

        .export-section-container {
            border-right: none !important;
            width: 85%;
            height: 90%;
            margin-top: 10%;

        .export-formats-container {
            display: flex;
            flex-direction: row;
            height: 30%;
            width: 100%;
            justify-content: space-between;

            div {
                width: 48%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: 1px solid @default-gray-color;
                border-radius: 5px;

                &:hover {
                    cursor: pointer;
                }

                span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                }
            }

            .export-format-active {
                background-color: @default-gray-color;
                border: 1px solid @default-blue-color;
            }

            .export-format-inactive {
                background-color: inherit;
            }
        }
    }
}
}

@media (max-width:@mobile-width) {

    .export-modal-format {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .export-section-container {
            width: 85%;
            height: 90%;
            margin-top: 10%;
            border-right: none !important;
        }

        .export-formats-container {
            display: flex;
            flex-direction: row;
            height: 30%;
            width: 100%;
            justify-content: space-between;

            div {
                width: 42.5%;
                height: 20vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: 1px solid @default-gray-color;
                border-radius: 5px;

                &:hover {
                    cursor: pointer;
                }

                span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                }
            }

            .export-format-active {
                background-color: @default-gray-color;
                border: 1px solid @default-blue-color;
                text-align: center;
            }

            .export-format-inactive {
                background-color: inherit;
                text-align: center;
            }
        }
    }
}

@primary-color: #2A54C5;