@import "styles/AntDesign/customTheme.less";

.reset-password, .reset-password-main-section {
	width: 100%;
}

.reset-password {
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;

	.ant-form {
		width: 100%;
		height: 100%;
	}

	.reset-password-main-section {
		height: 100%;
		display: flex;
		padding: @small-margin-padding @default-margin-padding;

		.reset-password-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: #F8F9FA;
			width: 25%;

		}

		.reset-password-form-items {
			width: 50%;
			padding: @small-margin-padding;
			margin: auto;

			.pass-rules {
				margin-bottom: 2vh;
				font-size: @font-size-small;
				color: @gray-hint-color;
				font-weight: 600;
			}
		}
	}
}

@primary-color: #2A54C5;