@import "styles/AntDesign/customTheme.less";

.logo-container {
	position: absolute;
	left: 3%;
	top: 3%;
	display: flex;
	flex-direction: column;
	cursor: pointer;

	.logo-swift {
		max-width: 280px;
	}
}


@media (max-width: @desktop-width) {
	.logo-container {
		left: 0;
		right: 0;
		top: 5%;
		width: 100%;
		justify-content: center;
		align-items: center;

		.logo-swift {
			max-width: 50%;
		}
	}
}

@primary-color: #2A54C5;