@import "styles/AntDesign/customTheme";

.ant-popover {
	padding-top: @small-margin-padding !important;
}

.ant-popover-arrow {
	display: none;
}

.ant-popover-inner {
	margin-right: @small-margin-padding;

	.ant-popover-inner-content {
		padding: @large-margin-padding;

		.profile-popup-content {
			display: inline-flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.name {
				font-size: @font-size-large;
				font-weight: 400;
			}

			.email {
				font-size: @font-size-default;
				font-weight: 500;
				color:@default-blue-color;
			}
		}
	}
}

@primary-color: #2A54C5;